<template>
  <div>
    <loader v-if="loading" :helperText="'Verifying subscription'"></loader>
  </div>
</template>


<script>
import MainService from "@/services/main.service";
import loader from "@/components/common/adm-loader";
import { setCompany, getCompany } from "@/helper/utils";
export default {
  name: "charge-state",
  components: {
    loader,
  },
  data() {
    return {
      loading: false,
      approved: false,
      subscription_id: null,
    };
  },
  beforeMount() {
    this.approved = this.$route.query.approved;
    this.subscription_id = this.$route.query.subscription_id;
    this.checkChargeStatus();
  },
  beforeRouteEnter: (to, from, next) => {
    if (to.params.company_id) {
      setCompany(to.params.company_id);
    }
    next();
  },
  methods: {
    checkChargeStatus() {
      this.loading = true;
      MainService.updateSubscriptionStatus(this.subscription_id)
        .then(({ data }) => {
          if (data.status === "active") {
            this.$router.push(`/company/${getCompany()}`);
            this.$snackbar.global.showSuccess("Charge approved");
          } else {
            this.$snackbar.global.showError("Charge was declined");
            this.$router.push(`/company/${getCompany()}/pricing`);
          }
        })
        .catch((err) => {
          this.$snackbar.global.showError(
            "Failed to verify subscription status",
            3000
          );
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
